import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentButtons from '@solid-ui-components/ContentButtons'
import './styles.css';

const styles = {
  minHeight: '5vh',
}

const HeroBlock02 = ({
  content: { text = [], buttons },
}) => {
  return (
    <Container sx={{ minHeight: styles.minHeight }}>
      <Flex
        sx={{
          minHeight: styles.minHeight,
        }}
      >
        <Box
          sx={{
            margin: '3rem 2rem',
            flexBasis: '100%',
            textAlign: [`center`, null, null, `left`]
          }}
          className="hero-banner-title"
        >
          <Reveal effect='fadeInDown'>
            <ContentText content={text} />
          </Reveal>
          {buttons && (
            <Reveal
              effect='fadeInRight'
              delay={1}
              css={css({ mb: [4, null, null, 0] })}
            >
              {buttons && (
                <>
                  <Divider space={3} />
                  <ContentButtons content={buttons} />
                </>
              )}
            </Reveal>
          )}
        </Box>
      </Flex>
    </Container>
  )
};

export default HeroBlock02
