import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'

const captionStyle = { fontStyle: 'italic', margin: '0 auto', padding: '1rem 0rem', maxWidth: '600px' }

const ContentBlock = ({
  content: { text, images },
  reverse
}) => (
  <Container sx={{ position: `relative` }}>
    <Flex sx={{ justifyContent: 'center', flexDirection: 'column', float: ['none', 'none', 'none', 'right'], ml: [null, null, null, 5], mb: 5 }}>
      <ContentImages content={{ images }} reverse={reverse} />
      {images && (
        <>
          <p style={captionStyle}>{images[0]["alt"]}</p>
        </>
      )}
    </Flex>
    <Box
      sx={{
        textAlign: ['center', 'center', 'center', 'left'],
      }}
    >
      <ContentText content={text} />
    </Box>
  </Container>
)

export default ContentBlock
